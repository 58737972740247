import React, { useEffect, useState } from 'react';
import Header from '../components/Header'
import ProductSection from '../components/ProductSection';
import RoomSection from '../components/RoomSection';
import BottomSheet from '../components/BottomSheet';
import API from '../components/utils/api';
import "react-activity/dist/Dots.css";
import { useAlert } from 'react-alert';
import { Helmet } from 'react-helmet';
import ScreenLoading from '../components/ScreenLoading';
import OutletSection from '../components/OutletSection';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import ModalClaim from '../components/modals/ModalClaim';
import HomeGallery from '../components/defaults/HomeGallery';
import HomeCategory from '../components/defaults/HomeCategory';
import { useHistory, Link } from "react-router-dom";
import HelpContact from '../components/defaults/HelpContact';

function EcommerceDashboard({token, currentUser, company}) {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [galleriesPromo, setGalleriesPromo] = useState([]);
  const [promo, setPromo] = useState({});
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [mitra, setMitra] = useState(false);

  const loadData = () => {
    let data = currentUser;
    if(data && data.id){
      if(data.group_user_id > 0){
        setMitra(true)
      }
    }
    
    if(company && company.id){
      loadLastPromo(company.id);
      if(company.websetting_galleries){
        setGalleries(company.websetting_galleries.filter((g) => g.category === 'home_app'));
        setGalleriesPromo(company.websetting_galleries.filter((g) => g.category === 'home_promo'));
      }
      loadCategories(company.id);
    }
  }

  const loadCategories = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true, active_promo: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
      })

    API.get(`brands`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data)
      })
  }

  const loadLastPromo = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true } };
    
    API.get(`last_promo`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromo(data)
      }).catch(error => {
      })
  }

  useEffect(() => {
    loadData();
  },[])

  return (
    company ? (
      <>
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
        
        {
          galleries.length > 0 ? (
            <ModalClaim token={token} currentUser={currentUser} company={company} openModal={openModal} galleries={galleries}/>
          ) : null
        }
        
        { mitra && currentUser ? (
          <div className="row" style={{marginTop: 20}}>
            <div className="col s12 m12 padding0">
              <div className="icon-apps" onClick={e => history.replace(`/reports`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>insert_chart</i>
                </div>
                <div className='icon-app-name'>Report</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/payments`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>exit_to_app</i>
                </div>
                <div className='icon-app-name'>Pembayaran</div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/blogs`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>ondemand_video</i>
                </div>
                <div className='icon-app-name'>
                  Blog
                </div>
              </div>
              <div className="icon-apps" onClick={e => history.replace(`/content_kit`)}>
                <div className="icon-app">
                  <i class="material-icons dp48" style={{color: (company.company_color1)}}>photo_camera</i>
                </div>
                <div className='icon-app-name'>Konten Kit</div>
              </div>
            </div>
          </div>
        ) : null }

        {/* <OutletSection token={token} currentUser={currentUser} currentCompany={company} context='dashboard'/> */}
        {
          (company.last_package && company.last_package.can_promo == true) && (promo && promo.id) ? (
            <ProductSection token={token} title={promo.title} currentUser={currentUser} currentCompany={company} promo={promo} promo_id={promo.id}/>
          ) : null
        }
        {
          galleriesPromo.length > 0 ? (
            <HomeGallery currentUser={currentUser} company={company} galleriesPromo={galleriesPromo}/>
          ) : null
        }
        <HomeCategory company={company} categories={categories} brands={brands}/>
        <ProductSection token={token} title='Produk Promo' currentUser={currentUser} currentCompany={company}/>
        <ProductSection token={token} title='Produk Terlaris' currentUser={currentUser} currentCompany={company}/>
        <ProductSection token={token} title='Produk Terbaru' currentUser={currentUser} currentCompany={company}/>
        <HelpContact company={company}/>
        <BottomSheet company={company}/>
      </>
    ) : (
      <ScreenLoading/>
    )
  );
}

export default EcommerceDashboard;