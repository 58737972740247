import React, { useEffect, useState } from 'react';
import Header from '../components/Header'
import ProductSection from '../components/ProductSection';
import RoomSection from '../components/RoomSection';
import BottomSheet from '../components/BottomSheet';
import API from '../components/utils/api';
import "react-activity/dist/Dots.css";
import { useAlert } from 'react-alert';
import { Helmet } from 'react-helmet';
import ScreenLoading from '../components/ScreenLoading';
import OutletSection from '../components/OutletSection';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import ModalClaim from '../components/modals/ModalClaim';
import HomeGallery from '../components/defaults/HomeGallery';
import HomeCategory from '../components/defaults/HomeCategory';
import HelpContact from '../components/defaults/HelpContact';

function RestaurantDashboard({token, currentUser, company}) {
  const [openModal, setOpenModal] = useState(false);
  const [galleriesPromo, setGalleriesPromo] = useState([]);
  const [promo, setPromo] = useState({});
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [galleries, setGalleries] = useState([]);

  const loadData = () => {
    if(company && company.id){
      loadLastPromo(company.id);
      if(company.websetting_galleries){
        setGalleries(company.websetting_galleries.filter((g) => g.category === 'home_app'));
        setGalleriesPromo(company.websetting_galleries.filter((g) => g.category === 'home_promo'));
      }
      loadCategories(company.id);
    }
  }

  const loadCategories = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true, active_promo: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
      })

    API.get(`brands`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data)
      })
  }

  const loadLastPromo = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true } };

    API.get(`last_promo`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromo(data)
      })
  }

  useEffect(() => {
    loadData();
  },[])

  return (
    company ? (
      <>
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.company_icon_url} />
          <link rel="icon" href={company.company_icon_url} />
        </Helmet>
        
        {
          galleries.length > 0 ? (
            <ModalClaim token={token} currentUser={currentUser} company={company} openModal={openModal} galleries={galleries}/>
          ) : null
        }
        {/* <OutletSection token={token} currentUser={currentUser} currentCompany={company} context='dashboard'/> */}
        {
          galleriesPromo.length > 0 ? (
            <HomeGallery currentUser={currentUser} company={company} galleriesPromo={galleriesPromo}/>
          ) : null
        }
        <RoomSection token={token} title='Reservasi Sekarang' currentUser={currentUser} currentCompany={company}/>
        {
          (company.last_package && company.last_package.can_promo == true) && (promo && promo.id) ? (
            <ProductSection token={token} title={promo.title} currentUser={currentUser} currentCompany={company} promo={promo} promo_id={promo.id}/>
          ) : null
        }
        <ProductSection token={token} title='Menu Promo' currentUser={currentUser} currentCompany={company}/>
        <ProductSection token={token} title='Menu Terlaris' currentUser={currentUser} currentCompany={company}/>
        <ProductSection token={token} title='Menu Terbaru' currentUser={currentUser} currentCompany={company}/>
        <HelpContact company={company}/>
        <BottomSheet company={company}/>
      </>
    ) : (
      <ScreenLoading/>
    )
  );
}

export default RestaurantDashboard;