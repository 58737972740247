import React, { useState, useEffect } from 'react'
import { useHistory, Link } from "react-router-dom";

import {
  WaIcon,
  ArowIcon,
  FbIcon,
  IgIcon
} from '../../assets'


function HelpContact({company}) {
  const messages = `Hi ${company?.full_name}, saya pengguna App ${company?.full_name} ingin bertanya`;
  
  useEffect(() => {
  },[])

  return (
    <div className="row p-top-10 margin-none">
      <div className="col s12">
        <span className='title-card-content'>Butuh Bantuan ?</span>
      </div>

      <div className="col s12">
        <a href={'https://api.whatsapp.com/send?phone='+company?.phone1+'&text='+messages} className='contact-help'>
          <div className='contact-wa'>
            <img src={WaIcon} />
          </div>
          <div className='contact-text'>
            <div>{company?.full_name} Customer Service</div>
            <div><b>{company?.phone1}</b> (chat only)</div>
          </div>
          <div className='contact-arow'>
            <img src={ArowIcon} />
          </div>
        </a>

        {
          company?.instagram ? (
            <a href={company?.instagram} className='contact-help'>
              <div className='contact-wa'>
                <img src={IgIcon} />
              </div>
              <div className='contact-text'>
                <div>Official Instagram</div>
                <div><b>{company?.full_name}</b></div>
              </div>
              <div className='contact-arow'>
                <img src={ArowIcon} />
              </div>
            </a>
          ) : null
        }

        {
          company?.facebook ? (
            <a href={company?.facebook} className='contact-help'>
              <div className='contact-wa'>
                <img src={FbIcon} />
              </div>
              <div className='contact-text'>
                <div>Official Facebook</div>
                <div><b>{company?.full_name}</b></div>
              </div>
              <div className='contact-arow'>
                <img src={ArowIcon} />
              </div>
            </a>
          ) : null
        }

      </div>
    </div>
  )
}

export default HelpContact;