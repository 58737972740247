import React, { useEffect, useState } from 'react';
import EcommerceDashboard from '../layouts/EcommerceDashboard';
import ReservationDashboard from '../layouts/ReservationDashboard';
import HotelDashboard from '../layouts/HotelDashboard';
import RestaurantDashboard from '../layouts/RestaurantDashboard';
import ScreenLoading from '../components/ScreenLoading';
import M from 'materialize-css';

import ReactSession from '../ReactSession';
import API from '../components/utils/api';
import "react-activity/dist/Dots.css";

function Dashboard() {
  const [currentUser, setCurrentUser] = useState();
  const [company, setCompany] = useState();
  const token = ReactSession.get("token");

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'company, websetting_galleries, staff, last_billing, last_package, outlet'}};

    API.get(`sessions`, config, {} )
      .then(res => {
        const data = res.data.data;
        ReactSession.set("current_company", data.company);
        setCurrentUser(data);
        setCompany(data.company);

        var elem = document.querySelector(".slider1");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });

        var elem = document.querySelector(".slider2");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });
      })
      .catch(error => {
        setCurrentUser();
        ReactSession.remove('token')
        ReactSession.remove('current_user')

        // let hostName = 'gadgetstore';
        // let hostName = window.location.hostname.split('.').slice(1).join('.')
        let hostName = window.location.hostname;

        const config = { params: {domain: hostName, include: 'company, websetting_galleries, last_billing, last_package'} };
        API.get(`get_company`, config )
          .then(res => {
            const data = res.data.data;
            ReactSession.set("current_company", data);
            setCompany(data);

            var elem = document.querySelector(".slider1");
            var instance = window.M.Carousel.init(elem, {
              fullWidth: true,
              indicators: true,
            });
    
            var elem = document.querySelector(".slider2");
            var instance = window.M.Carousel.init(elem, {
              fullWidth: true,
              indicators: true,
            });
          })
      })
  }

  useEffect(() => {
    loadData();

    M.AutoInit();
  },[])

  return (
    company ? (
      <>
        {
          company.is_hotel ? (
            <HotelDashboard token={token} currentUser={currentUser} company={company}/>
          ) : company.is_restaurant ? (
            <RestaurantDashboard token={token} currentUser={currentUser} company={company}/>
          ) : company.is_reservation ? (
            <ReservationDashboard token={token} currentUser={currentUser} company={company}/>
          ) : (
            <EcommerceDashboard token={token} currentUser={currentUser} company={company}/>
          ) 
        }
      </>
    ) : (
      <ScreenLoading/>
    )
  );
}

export default Dashboard;

const styles: StyleSheet = {
  banner_slide: ({
    padding: 70, textAlign: 'center', background: '#ccc', fontSize: 20
  })
}