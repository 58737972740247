import React, { useEffect, useState } from 'react';
import ReactSession from '../ReactSession';
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import API from '../components/utils/api';
import M from 'materialize-css';

const HeaderHome = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const current_order_count = ReactSession.get("current_order_count");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const actLogout = () => {
    const token = ReactSession.get("token");
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.delete('sessions', config )
      .then(res => {
        ReactSession.remove("token");
        ReactSession.remove("current_user");
        history.push("/login");

        let elements = document.querySelectorAll('.sidenav-overlay');
        elements.forEach((item: any) => {
          item.style.display = 'none';
        })
      })
      .catch(error => {
        ReactSession.remove('token')
        ReactSession.remove('current_user')
        history.push("/login");
        console.log(error.response);
      })
  }

  const [stickyClass, setStickyClass] = useState('relative');

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('nav-scroll') : setStickyClass('');
    }
  };

  useEffect(() => {
    M.AutoInit();
    window.addEventListener('scroll', stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
    
  },[])

  return (
    currentCompany ? (
      <>
        <ul className="sidenav" id="mobile-demo" style={{background: (currentCompany.company_color2)}}>
          { currentUser ? (
            <li className='sidenav-profile'>
              <div className='avatar'>
                <img src={currentUser.avatar_url}/>
              </div>
              <div className=''><b>{currentUser.full_name}</b></div>
              <div className=''>{currentUser.group_user_name}</div>
            </li>
          ) : null}
          <li><Link to="/dashboard" ><i className="material-icons">public</i> Home</Link></li>
          { currentUser && currentUser.affiliate === 'true' || currentUser && currentUser.affiliate === true ? (
            <li><Link to="/resellers" ><i className="material-icons">assignment_ind</i> Reff Reseller</Link></li>
          ) : null}
          <li><Link to="/products" ><i className="material-icons">content_paste_search</i> Shop</Link></li>
          <li><Link to="/whistlists" ><i className="material-icons">favorite</i> Whistlist</Link></li>
          <li><Link to="/orders" ><i className="material-icons">content_paste_go</i> Pesanan</Link></li>
          <li><Link to="/profile" ><i className="material-icons">account_circle</i> Profil</Link></li>
          <li><a href="#" onClick={() => {actLogout()}} ><i className="material-icons">power_settings_new</i> Keluar</a></li>
        </ul>
      </>
    ) : null
  )
}

HeaderHome.defaultProps = {
  title: 'Default'
}

export default HeaderHome
