import React, { useState, useEffect } from 'react'
import API from './../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useAlert } from 'react-alert';
import { useHistory, Link } from "react-router-dom";
import M from 'materialize-css';
import HeaderHome from '../../components/HeaderHome'

import {
  ArowIcon,
  PoIcon
} from '../../assets'

import ReactSession from '../../ReactSession';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function ModalClaim({token, currentUser, company, openModal, galleries}) {
  const history = useHistory();
  const alertPlug = useAlert();
  const [paymentNumber, setPaymentNumber] = useState('');
  const [virtualMoney, setVirtualMoney] = useState(0);
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [methodDelivery, setMethodDelivery] = useState("");
  const _methodDelivery = ReactSession.get("method_delivery");
  
  const postClaimSaldo = () => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
  
    const data = {
      category: 'out',
      virtual_money: virtualMoney,
      payment_number: paymentNumber
    }
    
    API.post(`claim_moneys`, data, config )
      .then(res => {
        const data = res.data.data;
        setSpinner(false);
        setOpen(false);
        alertPlug.success('Pengajual Berhasil, Tim Kami Akan Memproses Pengajuan Pencairan Dana Anda')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const setDeliveryMethod = (method) =>{
    setMethodDelivery(method);
    ReactSession.remove("method_delivery")
    ReactSession.set("method_delivery", method);
    history.push("/products");
  }

  useEffect(() => {
    setMethodDelivery(_methodDelivery);

    if(currentUser){
      setVirtualMoney(parseInt(currentUser?.virtual_money));
      setPaymentNumber(currentUser?.payment_bank_name+'-'+currentUser?.payment_bank_number);
    }

    setOpen(openModal);

    var elem = document.querySelector(".slider1");
    var instance = window.M.Carousel.init(elem, {
      fullWidth: true,
      indicators: true,
    });
  }, []);

  return (
    <>
      { open && currentUser ? (
        <div id="modal1" className='modal modal-visible'>
          <div className='modal-header'>
            Ajukan Pencairan Saldo <i className="material-icons modal-close"  onClick={(e) => setOpen(false)}>close</i>
          </div>
          <div className='modal-content'>
            <div className="col s12 p-left-0 p-right-0">
              <label className='custom-label'>Masukan Nominal</label>
              <div className="input-field input-new box-input-field">
                <input placeholder="Masukan Nominal" className="validate" value={virtualMoney} onChange={e => setVirtualMoney(e.target.value)}/>
              </div>
            </div>
            <div className="col s12 p-left-0 p-right-0">
              <div className="select-none-ui">
                <label className='custom-label'>Transfer Ke Rekening</label>
                <div className="input-field input-new">
                  <select className='select select-no-bdr' value={paymentNumber} onChange={e => setPaymentNumber(e.target.value)} >
                    <option value={currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}>
                      {currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col s12">
              { spinner ? (
                <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" >
                  <center><Dots color='#fff' /></center>
                </button>
              ) : (
                <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" onClick={(e) => postClaimSaldo()} >
                  Ajukan Sekarang
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
      
      <div className="navbar-side-menu">
        { currentUser ? (
          <a href="#" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons span-blue" style={{color: (company.themeplate === 'App Theme 2' ?  company.company_color1 : company.company_color1 ) }}>format_align_right</i> 
          </a>
        ) : (
          <Link to="/login">
            <i className="material-icons span-blue" style={{color: (company.themeplate === 'App Theme 2' ?  company.company_color1 : company.company_color1 ) }}>format_align_right</i> 
          </Link>
        ) }
      </div>

      <HeaderHome/>

      { galleries && galleries.length > 0 ? (
        <div className="row bg-grey m-bottom0">
          <div className="slider1 carousel carousel-slider carousel-home carousel-home1">
            {galleries.map((d, i) => 
              <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
            )}
          </div>
        </div>
      ) : (
        <div className="row bg-grey m-bottom0">
          <div className="carousel-slider carousel-home blank-carousel carousel-home1" style={{background: (company.company_color2)}}>
          </div>
        </div>
      ) }

      { currentUser ? (
        <>
          {
            ((company.last_package.can_virtual_point == true) && (company.config_features && company.config_features['use_point_virtual']) && 
              !company.cannot_virtual) || ((company.last_package.can_virtual_money == true) && (company.config_features && company.config_features['use_saldo']) && !company.cannot_virtual) ? (
              <div className="row bg-white" style={{marginBottom:0}}>
                <div className='col col s12 m12 bg-col-acumulation bottom-shadow'>
                  <div className='bg-acumulation-container'>
                    <div className="bg-acumulation-home">
                
                      {
                        (company.last_package.can_virtual_point == true) && (company.config_features && company.config_features['use_point_virtual']) && !company.cannot_virtual ? (
                          <>
                            <div className='col col s5 m5'>
                              <div className='acumulation-home pointer'>
                                <div className='acumulation-home-total' style={{textAlign: 'left'}}>
                                  <label className='acumulation-point' onClick={e => history.replace(`/history_points`)}>
                                    <i className="material-icons" style={{color: (company.company_color1)}}>stars</i>&nbsp;
                                    {currentUser.virtual_point ? numberFormat(currentUser.virtual_point) : numberFormat(0)} Poin
                                  </label>
                                </div>
                              </div>
                            </div>
                            {
                              (
                                (company.last_package.can_virtual_money == true) && 
                                (company.config_features && company.config_features['use_saldo']) && 
                                !company.cannot_virtual
                              ) ? null : (
                                <div className='col col s7 m7'>
                                  <div className='acumulation-home pointer' onClick={e => history.replace(`/history_points`)}>
                                    <div className='acumulation-home-total' style={{textAlign: 'right'}}>
                                      <img src={PoIcon} className='acumulation-poin-icon'/>
                                    </div>
                                  </div>
                                </div>
                              ) 
                            }
                          </>
                        ) : null 
                      }

                      {
                        (company.last_package.can_virtual_money == true) && (company.config_features && company.config_features['use_saldo']) && !company.cannot_virtual ? (
                          <div className='col col s7 m7'>
                            <div className='acumulation-home pointer' onClick={e => history.replace(`/history_moneys`)}>
                              <div className='acumulation-home-total' style={{textAlign: 'right'}}>
                                <i className="material-icons" style={{color: (company.company_color1)}}>trending_up</i>
                                <b>{currentUser.virtual_money ? currencyFormat(currentUser.virtual_money) : currencyFormat(0)}</b>
                              </div>
                            </div>
                          </div>
                        ) : null 
                      }

                      {
                        (company.last_package.can_virtual_money == true) && (company.config_features && company.config_features['use_saldo']) && !company.cannot_virtual ? (
                          <div className='col col s12 m12 hr-dry'>
                            <div className='acumulation-home pointer' onClick={(e) => setOpen(true)}>
                              <div className='acumulation-home-total' style={{textAlign: 'left'}}>
                                <div className='acumulation-input acumulation-input-top pointer' >
                                  Ajukan Pencairan Dana Sekarang Juga &nbsp;<img src={ArowIcon} style={{height: 10}}/>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null 
                      }             
                      
                    </div>
                  </div>

                  <div className='welcome-name'>
                    Hi {currentUser.full_name.split(' ')[0]}, Pesan Sekarang?
                  </div>
                  
                  {
                    (company.last_package && (company.last_package.can_order_table == true || company.last_package.multi_outlet == true) ) && (company.config_features && company.config_features['use_take_away']) ? (
                      <div className='dash-option-picker'>
                        <div className='row' style={{width: 'auto', marginBottom: 0}}>
                          <div className='col s6'>
                            <div className='pickup' onClick={(e)=> setDeliveryMethod('pickup')} style={{borderColor: (methodDelivery && methodDelivery === 'pickup' ? company.company_color1 : '')}}>
                              <div className='dash-option-title' style={{color: (company.company_color1)}}>Pick Up</div>
                              <div className='dash-option-desc'>
                                <div style={{float: 'left', marginRight: 60}}>Ambil di store tanpa antri</div>
                                <div className='dash-option-icon'><i class="material-icons" style={{color: (company.company_color1)}}>store</i></div>
                              </div>
                            </div>
                          </div>
                          <div className='col s6'>
                            <div className='delivery' onClick={(e)=> setDeliveryMethod('delivery')} style={{borderColor: (methodDelivery && methodDelivery === 'delivery' ? company.company_color1 : '')}}>
                              <div className='dash-option-title' style={{color: (company.company_color1)}}>Delivery</div>
                              <div className='dash-option-desc'>
                                <div style={{float: 'left', marginRight: 60}}>Pesanan diantar lokasi</div>
                                <div className='dash-option-icon'><i class="material-icons" style={{color: (company.company_color1)}}>directions_bike</i></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            ) : null 
          }
        </>
      ) : (
        <div className="row bg-grey" style={{marginBottom:0}}>
          <div className='col col s12 m12 bg-col-acumulation'>
            <div className='bg-acumulation-container' style={{maxHeight: 80}}>
              <div className="bg-acumulation-home">

                <div className='col col s12 m12 hr-bottom-dry'>
                  <div className='acumulation-home pointer' onClick={(e) => setOpen(true)}>
                    <div className='acumulation-home-total' style={{textAlign: 'left'}}>
                      <div className='acumulation-input acumulation-input-top pointer' >
                        <b>Selamat Datang di App {company?.full_name} !</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col col s6 m6'>
                  <div className='acumulation-home pointer btn-register-login' onClick={e => history.replace(`/register`)}>
                    <div className='acumulation-home-total'>
                      <i className="material-icons" style={{color: (company.company_color1)}}>account_circle</i>
                      <span style={{fontSize: 14}}>&nbsp;Register</span>
                    </div>
                  </div>
                </div>

                <div className='col col s6 m6'>
                  <div className='acumulation-home pointer btn-register-login' onClick={e => history.replace(`/login`)}>
                    <div className='acumulation-home-total'>
                      <i className="material-icons" style={{color: (company.company_color1)}}>lock_open</i>
                      <span style={{fontSize: 14}}>&nbsp;Login</span>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalClaim;