import React, { useState, useEffect } from 'react'
import ReactSession from '../ReactSession';
import BottomSheetDefault from '../components/defaults/BottomSheetDefault';
import BottomSheetReservation from './defaults/BottomSheetReservation';

const BottomSheet = () => {
  const currentCompany = ReactSession.get("current_company");

  useEffect(() => {
  }, []);

  return (
    currentCompany ? (
      <>
        {
          currentCompany.is_hotel ? (
            <BottomSheetReservation/>
          ) : currentCompany.is_restaurant ? (
            <BottomSheetReservation/>
          ) : currentCompany.is_reservation ? (
            <BottomSheetReservation/>
          ) : (
            <BottomSheetDefault/>
          ) 
        }
      </>
    ) : null
  )
}

export default BottomSheet
