import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { DatePicker, Autocomplete } from "react-materialize";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { format } from "date-fns";

import {
  WaIcon,
  ArowIcon,
  FbIcon,
  IgIcon
} from '../assets'

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Carts = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const currentCompany = ReactSession.get("current_company");
  const {id} = useParams();
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const [order, setOrder] = useState({});
  const [bookingOrder, setBookingOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [company, setCompany] = useState({});
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [spinnerImg, setSpinnerImg] = useState(false);

  const [invoiceCode, setInvoiceCode] = useState('');
  const [paidDate, setPaidDate] = useState('');
  const [fromAccount, setFromAccount] = useState('');
  const [toAccount, setToAccount] = useState('');
  const [price, setPrice] = useState('');
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();

  const [errorMessage, setErrorMessage] = useState('');
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [spinner, setSpinner] = useState(false);

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    const fd = new FormData();
    fd.append('invoice_code', invoiceCode);
    fd.append('paid_date', paidDate);
    fd.append('from_account', fromAccount);
    fd.append('to_account', toAccount);
    fd.append('price', price);
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    
    API.post('confirmations', fd, config )
      .then(res => {
        setInvoiceCode('');
        setPaidDate('');
        setFromAccount('');
        setToAccount('');
        setPrice('');
        setOpenModal(false);
        setSpinner(false);
        loadData();
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
    
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'order_items, product, order_item_codes, outlet, confirmations, booking_order, room'} };
    API.get(`orders/${id}`, config)
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setOrder(data);
        setOrderItems(data.order_items);
        setInvoiceCode(data.code);
        setBookingOrder(data.booking_order);
        let price = (parseInt(data.store_price)+(parseInt(data.shipping_price)+parseInt(data.fee_cod)+parseInt(data.uniq_code)) - parseInt(data.subsidi)) - parseInt(data.virtual_money)
        setPrice(price);
        const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'payment_accounts'} };
        API.get(`user_company`, config2)
          .then(res => {
            const com = res.data.data;
            setCompany(com);
            setPaymentNumbers(com.payment_accounts);
          })
      })
  }

  const cancelOrder = () => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const fd = new FormData();
    fd.append('status_payment', 'cancelled');
    fd.append('status_shipping', 'cancelled');
    fd.append('include', 'order_items, product, order_item_codes');
    API.put(`orders/${id}`, fd, config )
      .then(res => {
        loadData();
      })
  }

  const receiveOrder = () => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const fd = new FormData();
    fd.append('status_shipping', 'received');
    fd.append('include', 'order_items, product, order_item_codes');
    API.put(`orders/${id}`, fd, config )
      .then(res => {
        loadData();
      })
  }

  const getNotePaymentLink = (code) => {
    return apiBaseUrl+'/orders/'+ code +'.pdf';
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/login")
    }

    loadData();
  
  },[])

  return (
    currentCompany ? (
    <>
      { openModal ? (
        <div id="modal1" className='modal modal-visible'>
          <div className='modal-header'>
            Upload Bukti Pembayaran <i className="material-icons modal-close"  onClick={(e) => setOpenModal(false)}>close</i>
          </div>
          <div className='modal-content'>
            <div className='row'>
              <div className="col s12 m12">
                <div className='avatar-user'>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: 120,
                      height: 120
                    }}
                  />
                  { spinnerImg ? (
                    <div className="col s12 m12" >
                      <center><Dots color='#444' /></center>
                    </div>
                  ) : null }
                </div>
              </div>
              <div className="p-bottom-10">
                <label className='label-new'>
                  Kode Invoice
                </label>
                <div className="input-field input-new margin0">
                  <input className="input-back" value={invoiceCode} onChange={e => setInvoiceCode(e.target.value)} disabled />
                </div>
              </div>
              <div className="p-bottom-10">
                <label className='label-new'>
                  Tanggal Pembayaran
                </label>
                <div className="input-field input-new margin0 datepicker-ipt">
                  <DatePicker className='input-radius' placeholder='Dari Tanggal' value={paidDate} onChange={e => setPaidDate( format(e, "yyyy-MM-dd") )}/>
                </div>
              </div>
              <div className="p-bottom-10">
                <label className='label-new'>
                  Akun Pembayaran
                </label>
                <div className="input-field input-new margin0">
                  <input className="input-back" placeholder='ex: BCA XXXX' value={fromAccount} onChange={e => setFromAccount(e.target.value)} />
                </div>
              </div>
              <div className="p-bottom-10">
                <label className='label-new'>
                  Akun Tujuan Pembayaran
                </label>
                <div className="input-field input-new margin0">
                  <input className="input-back" placeholder='ex: BCA XXXX' value={toAccount} onChange={e => setToAccount(e.target.value)} />
                </div>
              </div>
              <div className="p-bottom-10">
                { spinner ? (
                  <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" >
                    <center><Dots color='#fff' /></center>
                  </button>
                ) : (
                  <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" onClick={(e) => actPost()} >
                    Kirim Sekarang
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <HeaderScreen title={"Detail Pesanan "+ (order.code ? order.code.toUpperCase() : '')} link="/orders"/>
      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className='sidenav-overlay'></div>
          <div className="row m-bottom0 back-white">
            <div className="">
              <div className="card horizontal card-cart">
                <b>Kode Pesanan</b>
                <label className='btn-right-label2'>
                  {order.code ? order.code.toUpperCase() : null}
                </label>
              </div>

              <div className="row border-bottom">
                <div className="col s12 m12">
                  <div className='sub-order-total'> 
                    Total Pembayaran 
                    <br/>
                    <span className='total'>
                      {currencyFormat((parseInt(order.store_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)+parseInt(order.uniq_code)) - parseInt(order.subsidi)) - parseInt(order.virtual_money))}
                    </span>
                    <br/>
                    <span className='status-payment' style={styles.status_payment(order.status_payment)}>{order.status_payment ? order.status_payment_txt.toUpperCase() : null}</span>
                    <br/>

                    {
                       order.in_outlet === false && paymentNumbers && order.payment_method && order.payment_method.includes('Transfer') && order.status_payment !== 'paid' ? (
                        <small>Batas Waktu Pembayaran {order.due_date}</small>
                       ) : null
                    }

                    { order.status_payment == 'pending' ? (
                      <>
                        <br/>
                        <button onClick={cancelOrder} className='btn btn-cancel' style={{background: 'red'}}>Batalkan Pesanan</button>
                      </>
                    ) : null}

                    { order.status_payment == 'paid' && order.status_shipping != 'received' && order.order_items.length > 0 ? (
                      <>
                        <br/>
                        <button onClick={receiveOrder} className='btn btn-accept' style={{background: 'green'}}>Pesanan Diterima</button>
                      </>
                    ) : null}

                    {
                      order.code ? (
                        <>
                          <br/>
                          <a href={getNotePaymentLink(order.code)} className="btn btn-accept" style={{background: '#444'}} target='_blank'>DOWNLOAD NOTA PEMBELIAN</a>
                          </>
                      ) : null
                    }
                  </div>
                </div>
              </div>
              
              {
                order.in_outlet === true && (order.customer_payment_method === 'CASH' || order.customer_payment_method === 'QRIS' )? (
                  <div className="row border-bottom">
                    <div className="col s12 m12">
                      <div className='box-bank-number'> 
                        <small>Silahkan Lakukan Pembayaran di Kasir Secara Langsung</small>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    { paymentNumbers && order.payment_method && order.payment_method.includes('Transfer') && order.status_payment != 'paid' ? (
                      <div className="row border-bottom">
                        <div className="col s12 m12">
                          <div className='box-bank-number'> 
                            <p>Silahkan Untuk Pembayaran Via Transfer Bisa Ke Norek Dibawah Ini, Lalu Konfirmasi Pembayaran Anda Kepada Customer Service Kami :</p>
                            {
                              paymentNumbers.map((p) => {
                                return(
                                  <div className='list-bank-number'>
                                    <b>{p.name} {p.code}</b>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    ) : order.status_payment === 'paid' ? null : (
                      <div className="row border-bottom">
                        <div className="col s12 m12">
                          <div className='box-bank-number'> 
                            <small>Silahkan Sediakan Pembayaran Pesanan Anda <br/>Ketika Barang Sudah Sampai Dilokasi Pengiriman Yang Dituju.</small>
                          </div>
                        </div>
                      </div>
                    ) }
                  </>
                )
              }


              {/* <div className="card horizontal card-cart">
                <b>{order.in_outlet === false ? 'Data Pengirim' : 'Data Outlet'}</b>
              </div> */}

              <div className="card horizontal card-cart back-grey">
                <div className="row margin-none back-grey">
                  <div className="col s12 m12" style={{padding:0}}>
                    <div className='contact-help' style={{marginBottom:0}}>
                      <div className='contact-wa'>
                        <div className='icon-outlet'>
                          <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>store</i>  
                        </div>
                      </div>

                      <div className='contact-text' style={{width: '80%'}}>
                        <div style={{fontSize:16}}><b>{order.store_name}</b></div>
                        <div className='box-address' style={{fontWeight: 400}}>
                          <div style={{marginBottom:5}}>
                            {order.store_address}
                          </div>
                          {
                            order.outlet && order?.outlet?.clock_start && order?.outlet?.clock_end  ? (
                              <>
                                <div style={{color: '#444'}}><b>Tutup:</b> {order?.outlet?.clock_start} - {order?.outlet?.clock_end} WIB </div>
                                {/* <div style={{color: '#444'}}><b>Phone:</b> {order.store_number} </div> */}
                              </>
                            ) : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card horizontal card-cart">
                <b>Dipesan Oleh</b>
                {
                  order.order_items && order.order_items.length > 0 ? (
                    <label className='btn-right-label2' style={styles.status(order.status_shipping)}>
                      {order.status_shipping ? order.status_shipping_txt.toUpperCase() : null}
                    </label>
                  ) : null
                }
              </div>

              <div className="card horizontal card-cart back-grey">
                <div className="row margin-none back-grey">
                  <div className="col s12 m12" style={{padding:0}}>
                    <div className='contact-help' style={{marginBottom:0}}>
                      <div className='contact-wa'>
                        <div className='icon-outlet'>
                          <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>contacts</i>  
                        </div>
                      </div>

                      <div className='contact-text' style={{width: '80%'}}>
                        <div style={{fontSize:16}}><b>{order.customer_name}</b></div>
                        <div className='box-address' style={{fontWeight: 400}}>
                          <div style={{marginBottom:5}}>
                            {
                              order.in_outlet === false && order.order_items.length > 0 ? (
                                <span>
                                  {order.customer_address}, {order.customer_district}, {order.customer_city}, {order.customer_province}
                                </span>
                              ) : (
                                <>
                                  {
                                    order.outlet_table ? (
                                      <span>
                                        Nomor Meja <b>{order.outlet_table}</b>
                                      </span>
                                    ) : null
                                  }
                                </>
                              )
                            }
                          </div>
                          <div style={{color: '#444'}}><b>Phone:</b> {order.customer_phone} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="card horizontal card-cart">
                <b>Detail Pemesanan</b>
                <label className='btn-right-label2'>
                  {order.order_date}
                </label>
              </div>

              { orderItems ? (
                orderItems.map((oi) => {
                  return (
                    <div key={oi.id} className="card horizontal card-cart">
                      <div className="card-image">
                        <img src={oi.product.image_url}/>
                      </div>
                      <div className="card-stacked">
                        <div className="card-content padding-top0">
                          <div className='col col s8 m8 padding0'>
                            <div className='card-product'>
                              <Link to={'product/'+oi.product_id} >{oi.product_name}</Link>
                            </div>
                            { oi.variant_name_1 ? (
                              <div className='card-variant'>
                                <label className='label label-default'>Varian {oi.variant_name_1} {oi.variant_name_2}</label>
                              </div>
                            ) : null}
                            <div>
                              { 
                                oi.order_item_codes.map((oc) => {
                                  return (
                                    <label className='label label-default' style={{marginRight: 2, marginTop: 5, fontSize: 8}}>
                                      {oc.code}
                                    </label>
                                  )
                                })
                              }
                            </div>
                          </div>
                          
                          <div className='col col s4 m4 padding0'>
                            <div className='card-variant text-right'>
                              <span className=''>
                                {oi.quantity} x
                                <br/>
                                { oi.discount > 0 ? (
                                  <>
                                    <span className='discount-price'>{currencyFormat(parseInt(oi.store_price)+(parseInt(oi.discount)/parseInt(oi.quantity)))}</span>
                                    <br/>
                                  </>
                                ) : null }
                                {currencyFormat(oi.store_price)}
                              </span>
                            </div>
                            { oi.member_point > 0 ? (
                              <div className='card-variant text-right'>
                                <label className='label label-point'>{oi.member_point} POIN</label>
                              </div>
                            ) : null }
                            { oi.member_cashback > 0 ? (
                              <div className='card-variant text-right'>
                                <label className='label label-cashback'>{currencyFormat(oi.member_cashback)} Cashback</label>
                              </div>
                            ) : null }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : null }

              { bookingOrder && bookingOrder.room ? (
                <>
                  <div className="card horizontal card-cart">
                    <div className="booking-order-box">
                      <div className="card-image">
                        <img src={bookingOrder?.room?.image_url}/>
                      </div>
                      <div className="card-stacked">
                        <div className="card-content padding-top0">
                          <div className='col col s8 m8 padding0'>
                            <div className='' style={{cursor: 'pointer'}}>Reservasi {bookingOrder?.room?.name}</div>
                            {
                              currentCompany.is_hotel ? (
                                <div className='card-variant'>
                                  <span>{bookingOrder.start_date} s/d {bookingOrder.end_date} </span>
                                  <br/>
                                  <span>{bookingOrder.booking_days} Hari, {bookingOrder.booking_people} Orang</span>
                                </div>
                              ) : (
                                <div className='card-variant'>
                                  <span>{bookingOrder.start_date} {bookingOrder.booking_time}</span>
                                  <br/>
                                  <span>{bookingOrder.booking_people} Orang</span>
                                </div>
                              )
                            }
                          </div>
                          <div className='col col s4 m4 padding0'>
                            <div className='card-variant text-right'>
                              {currencyFormat(parseInt(bookingOrder.booking_price))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    bookingOrder.booking_note ? (
                      <div className="card horizontal card-cart">
                        <div className="booking-order-box">
                          <b>Catatan</b> : {bookingOrder.booking_note}
                        </div>
                      </div>
                    ) : null
                  }
                </>
              ) : null }

              {
                order.in_outlet === false && order.order_items.length > 0 ? (
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                        <b>Berat</b>
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                        <span>{order.expedition_weight} gr</span>
                      </div>
                    </div>
                  </div>
                ) : null
              }

              { order.expedition_number && order.expedition_number.length > 4 && order.order_items.length > 0 ? (
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                      <b>Nomor Resi</b>
                    </div>
                    <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                      <span>{order.expedition_number}</span>
                    </div>
                  </div>
                </div>
              ) : null}

              {
                order.in_outlet === false && order.order_items.length > 0 ? (
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                        <b>{order.expedition_name ? order.expedition_name.toUpperCase() : null} {order.expedition_service}</b>
                      </div>
                      <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                        <b>{currencyFormat(order.shipping_price)}</b>
                      </div>  
                    </div>
                  </div>
                ) : null
              }

              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                    <b>Transaksi Anda</b>
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                    <b>Rincian Harga</b>
                  </div>
                </div>
              </div>
              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Subtotal Produk Anda
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    {currencyFormat(order.store_price)}
                  </div>
                  {
                    order.in_outlet === false && !order?.booking_order?.id ? (
                      <>
                        <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                          Subtotal Ongkos Kirim
                        </div>
                        <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                          {currencyFormat( (parseInt(order.shipping_price)) )}
                        </div>
                      </>
                    ) : null
                  }

                  {
                    order.uniq_code > 0 ? (
                      <>
                        <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                          Kode Unik
                        </div>
                        <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                          {currencyFormat((parseInt(order.uniq_code)))}
                        </div>
                      </>
                    ) : null
                  }
                  { order.customer_payment_method === 'COD' && order.order_items.length > 0 ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Biaya Penanganan COD
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat( (parseInt(order.fee_cod)) )}
                      </div>
                    </>
                  ) : null}

                  { order.subsidi > 0 ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Potongan Subsidi
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        - {currencyFormat(order.subsidi)}
                      </div>
                    </>
                  ) : null }
                  
                  { parseInt(order.virtual_money) > 0 ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        BAYAR PAKAI SALDO
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        - {currencyFormat(order.virtual_money)}
                      </div>
                    </>
                  ) : null
                  }
                  
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    <b>TOTAL PEMBAYARAN ANDA</b>
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    <b>{currencyFormat((parseInt(order.store_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)+parseInt(order.uniq_code)) - parseInt(order.subsidi)) - parseInt(order.virtual_money))}</b>
                  </div>
                </div>
              </div>

              { order.dropship === true && order.order_items.length > 0 ? (
                <>
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                        <b>Detail Tagihan Ke Konsumen</b>
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                        <b>Rincian Harga</b>
                      </div>
                    </div>
                  </div>
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Subtotal Produk Konsumen
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat(order.sell_price)}
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Subtotal Ongkos Kirim
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat((parseInt(order.shipping_price)))}
                      </div>
                      { order.dropship === true && order.customer_payment_method === 'COD' ? (
                        <>
                          <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                            Biaya Penanganan COD
                          </div>
                          <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                            {currencyFormat( (parseInt(order.fee_cod)) )}
                          </div>
                        </>
                      ) : null}
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        <b>TOTAL {order.customer_payment_method.toUpperCase()}</b>
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        <b>{currencyFormat(parseInt(order.sell_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)))}</b>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {
                order.status_payment !== 'paid' ? (
                  <div className="row add-chart-seaction" style={{paddingTop:20}}>
                    <div className="col s12 m12">
                      <div className='card horizontal card-cart back-grey'>
                        <a href={'https://api.whatsapp.com/send?phone='+company.phone1+'&text=Hallo%20admin,%20Saya%20mau%20konfirmasi%20pembayaran%20lewat%20aplikasi%20'+currentCompany.full_name+'%20nomor%20pesanan%20'+order.code} className='contact-help'>
                          <div className='contact-wa'>
                            <img src={WaIcon} />
                          </div>
                          <div className='contact-text'>
                            <div>Konfirmasi Via Customer Service</div>
                            <div><b>{company?.phone1}</b> (chat only)</div>
                          </div>
                          <div className='contact-arow'>
                            <img src={ArowIcon} />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col s12 m12">
                      {
                        order.confirmations && order.confirmations.length > 0 ? (
                          <button className='btn btn-confirm' style={{background: (company.company_color3)}}>
                            <i class="material-icons dp48 icon-fa">check</i> <span className='span-icon-fa'>Bukti Pembayaran Sudah Dikirim</span>
                          </button>
                        ) : (
                          <button onClick={(e) => setOpenModal(true)} className='btn btn-confirm' style={{background: (company.company_color3)}}>
                            <i class="material-icons dp48 icon-fa">file_upload</i> &nbsp;<span className='span-icon-fa'>Upload Bukti Pembayaran</span>
                          </button>
                        )
                      }
                    </div>
                  </div>
                ) : null
              }

              {
                order.status_payment === 'paid' ? (
                  <div className="row add-chart-seaction" style={{paddingTop:20}}>
                    <div className="col s12 m12">
                      <div className='card horizontal card-cart back-grey'>
                        <a href={'https://api.whatsapp.com/send?phone='+company.phone1+'&text=Hallo%20admin,%20Saya%20mau%20menanyakan%20pesanan%20lewat%20aplikasi%20'+currentCompany.full_name+'%20nomor%20pesanan%20'+order.code} className='contact-help'>
                          <div className='contact-wa'>
                            <img src={WaIcon} />
                          </div>
                          <div className='contact-text'>
                            <div>Hubungi Customer Service</div>
                            <div><b>{company?.phone1}</b> (chat only)</div>
                          </div>
                          <div className='contact-arow'>
                            <img src={ArowIcon} />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null 
              }
            </div>
          </div>
        </>
      )}
    </>
    ) : null
  );
}

export default Carts;

const styles: StyleSheet = {
  status: (status) => ({
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
  }),
  status_payment: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}
