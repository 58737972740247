import React, { useState, useEffect } from 'react'
import API from './utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import ReactSession from '../ReactSession';
import LinesEllipsis from 'react-lines-ellipsis';

import {
  WaIcon,
  ArowIcon,
  FbIcon,
  IgIcon
} from '../assets'


const OutletSection = ({token, currentUser, currentCompany, context}) => {
  const [outlets, setOutlets] = useState([]);

  const loadData = () => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {paginate: false, by_status: true} };

    API.get(`outlets`, config)
      .then(res => {
        const data = res.data.data;
        setOutlets(data)
      })
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {
        currentUser && currentCompany && (currentCompany.last_package && (currentCompany.last_package.can_multi_outlet == true)) && outlets.length > 0 ? (       
          <div className="row margin-none back-grey">
            <div className="col s12 m12">
              <Link to={'/outlets'} className='contact-help' style={{marginBottom:0}}>
                <div className='contact-wa'>
                  <div className='icon-outlet'>
                    <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>store</i>  
                  </div>
                </div>

                {
                  currentUser.outlet && currentUser.outlet.name ? (
                    <>
                      <div className='contact-text'>
                        <div style={{fontSize:14}}><b>{currentUser?.outlet?.name}</b></div>
                        <div className='box-address' style={{fontWeight: 400}}>
                          {/* {currentUser?.outlet?.address}, {currentUser?.outlet?.district}, {currentUser?.outlet?.city}, {currentUser?.outlet?.province}
                          <br/> */}
                          <span style={{color: '#444'}}><b>Tutup:</b> {currentUser?.outlet?.clock_start} - {currentUser?.outlet?.clock_end} WIB </span>
                        </div>
                      </div>
                      <div className='contact-arow'>
                        <div className='icon-arrow-box'>
                          <img src={ArowIcon} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='contact-text'>
                        <div><b>Belum ditentukan</b></div>
                        <div>Lokasi pembelian outlet belum ditentukan</div>
                      </div>
                      <div className='contact-arow'>
                        <div className='icon-arrow-box'>
                          <img src={ArowIcon} />
                        </div>
                      </div>
                    </>
                  )
                }
              </Link>
            </div>
          </div>
        ) : null
      }
    </>
  )
}

export default OutletSection
