import React, { useEffect } from 'react';
import M from 'materialize-css';

function HomeGallery({currentUser, company, galleriesPromo}) {
  useEffect(() => {
    var elem = document.querySelector(".slider2");
    var instance = window.M.Carousel.init(elem, {
      fullWidth: true,
      indicators: true,
    });
  },[])

  return (
    <>
      { galleriesPromo && galleriesPromo.length > 0 ? (
        <div className="row m-bottom0" style={{padding: 10, paddingBottom: 0}}>
          <div className="slider2 carousel carousel-slider carousel-home" 
              style={{
                      top: (
                        (currentUser &&
                        (company.last_package &&
                          (company.last_package.can_virtual_money == false && company.last_package.can_virtual_point == false)
                        )
                      ) ? -40 : (currentUser && currentUser.group_user_id === 0 &&
                        (company.last_package &&
                          (company.last_package.can_virtual_money == true && company.last_package.can_virtual_point == false) ||
                          (company.last_package.can_virtual_money == false && company.last_package.can_virtual_point == true)
                        )
                      ) ? -40 : 0)
                    }}>
            {galleriesPromo.map((d, i) =>
              <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
            )}
          </div>
        </div>
      ) : (
        <div className="row m-bottom0" style={{padding: 10, paddingBottom: 0}}>
          <div className="slider2 carousel carousel-slider carousel-home" style={{top: 0}}>
            <div className='carousel-item active' style={styles.banner_slide}>BANNER</div>
          </div>
        </div>
      ) }
    </>
  )
}

export default HomeGallery;
const styles: StyleSheet = {
  banner_slide: ({
    padding: 70, textAlign: 'center', background: '#ccc', fontSize: 20
  })
}