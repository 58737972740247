import React, { useState, useEffect } from 'react'
import { useHistory, Link } from "react-router-dom";

function HomeCategory({company, categories, brands}) {
  useEffect(() => {

  },[])

  return (
    <>
      { company && company.is_ecommerce ? (
        <>
          { brands && brands.length > 4 ? (
            <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
              <div className="col s12 m12" style={{padding: 0}}>
                <div className='scroll-touch'>
                  <div className='scroll-container'>

                    {
                      brands.map((p) => {
                        return (
                          <Link to={'/products?brand_id='+p.id} className="icon-categories">
                            <div className="icon-image">
                              <img src={p.image_url}/>
                            </div>
                            <div className='icon-category-name'>{p.name}</div>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : null }
        </>
      ) : (
        <>
          { categories && categories.length > 3 ? (
            <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
              <div className="col s12 m12" style={{padding: 0}}>
                <div className='scroll-touch'>
                  <div className='scroll-container'>

                    {
                      categories.map((p) => {
                        return (
                          <Link to={'/products?category='+p.id} className="icon-categories">
                            <div className="icon-image">
                              <img src={p.image_url}/>
                            </div>
                            <div className='icon-category-name'>{p.name}</div>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : null }

          { categories && categories.length === 0 ? (
            <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
              <div className="col s12 m12" style={{padding: 0}}>
                <div className='scroll-touch'>
                  <div className='scroll-container'>
                    {
                      [1,2,3,4,5,6,8].map((p) => {
                        return (
                          <Link to={'#'} className="icon-categories">
                            <div className="icon-image" style={{background: '#ccc'}}>
                            </div>
                            <div className='icon-category-name'>Kategori</div>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : null }
        </>
      )}
    </>
  )
}

export default HomeCategory;